// extracted by mini-css-extract-plugin
export var afterCaption = "maine_digest_2022_05-module--afterCaption--a6fdb";
export var colWrapper = "maine_digest_2022_05-module--colWrapper--c633f";
export var columnWrapper = "maine_digest_2022_05-module--columnWrapper--14bc3";
export var dateline = "maine_digest_2022_05-module--dateline--4bc34";
export var heading = "maine_digest_2022_05-module--heading--34382";
export var headingLogo = "maine_digest_2022_05-module--headingLogo--fb065";
export var headingWrapper = "maine_digest_2022_05-module--headingWrapper--9e122";
export var heroImage = "maine_digest_2022_05-module--heroImage--228fb";
export var instance = "maine_digest_2022_05-module--instance--eaf9d";
export var latestnewsarticleheadline = "maine_digest_2022_05-module--latestnewsarticleheadline--1fefe";
export var leftCol = "maine_digest_2022_05-module--leftCol--8b440";
export var maineDigest = "maine_digest_2022_05-module--maineDigest--80def";
export var photoCaption = "maine_digest_2022_05-module--photoCaption--567d5";
export var rightCol = "maine_digest_2022_05-module--rightCol--434af";
export var subheading = "maine_digest_2022_05-module--subheading--2414d";
export var textWrapper = "maine_digest_2022_05-module--textWrapper--47d3a";
export var whatsNew = "maine_digest_2022_05-module--whatsNew--e9ff1";